import React, { useState, useCallback, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ImageGallery from 'react-image-gallery';

export default function ProjectTypeItem({ item, allProjects, currentPosition }) {

    const [show, setShow] = useState(false);
    const [index, setIndex] = useState(currentPosition);
    const mod = (n, m) => ((n % m) + m) % m;
    const forwards = useCallback(() => setIndex(state => mod(state + 1, allProjects.length)), [setIndex, allProjects]);
    const backwards = useCallback(() => setIndex(state => mod(state - 1, allProjects.length)), [setIndex, allProjects]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        // console.log(item)
    }, [])

    return (
        < div className="col-12 col-md-6 col-lg-4 p-4" >
            <div className="prod_img_view img-hover mb-3" onClick={handleShow}>
                <figure className="m-0 p-0"><img className="img-round" src={item.projectHeroImage} alt={item.projectName} /></figure>
                <div className="carousel-caption txt-over">
                    <p>{item.projectName}</p>
                    <h5>{item.projectLocation}</h5>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} dialogClassName="modal-90w">
                <Modal.Header className='justify-content-center'>
                    <div className="container p-3 m-0">
                        <div className="row">
                            <div className="col-6"><button type="submit" onClick={backwards} className="btn btn-outline-dark"><span className="bi-chevron-left"></span>Previous</button></div>
                            <div className="col-6"><button type="submit" onClick={forwards} className="btn btn-outline-dark float-end">Next<span className="bi-chevron-right"></span></button></div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center">
                    <div className="container p-3 m-0">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <ImageGallery originalHeight={"500px"} showPlayButton={false} showFullscreenButton={false} useBrowserFullscreen={false} showIndex={true} showThumbnails={false} startIndex={0} items={allProjects[index].projectImages} />
                            </div>
                            <div className="col-12 col-lg-6 mt-3 mt-lg-0 txt-project">
                                <h1>{allProjects[index].projectName}</h1>
                                <p>{allProjects[index].projectLocation}</p>
                                <p>{allProjects[index].projectDescription.split("^").map((item, i) => <p key={i}>{item}</p>)}</p>
                                <p>{allProjects[index].projectDescription2}</p>
                                <p className=""><span className="bi-border-outer" style={{ fontSize: '1.5em', marginRight: '10px' }}></span>Project Size: {allProjects[index].projectSize}</p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    );
}