import React from 'react';
import { Link } from 'react-router-dom'


export default function HeaderStatic({ banner }) {
    return (
        <div>
            <div className="container-fluid hd-test hd-container hd-fade">
                    <div className="row">
                        <div className="col-12 col-lg-5 offset-lg-1 hd-logo">
                            <Link to={`/`}><img className="logo-big" src="/images/logocw.png" alt="Main Logo"></img></Link>
                        </div>
                        <div className="col-12 col-lg-5 no-wrap text-right hd-flex">
                            <p className="mt-0 mb-2">
                                <span className="txt-link"><Link to={`/`} style={{ color: '#fff' }}>HOME</Link></span>
                                <span className="txt-link"><Link to={`/projects`} style={{ color: '#fff', bottom: 0 + 'px' }}>PROJECTS</Link></span>
                                <span className="txt-link"><Link to={`/team`} style={{ color: '#fff' }}>OUR TEAM</Link></span>
                                <span className="txt-link"><Link to={`/about`} style={{ color: '#fff' }}>ABOUT</Link></span>
                                <span className="txt-link p-0"><Link to={`/contact`} style={{ color: '#fff' }}>CONTACT</Link></span>
                            </p>
                        </div>
                        <div className="col-lg-1"></div>
                    </div>
            </div>
            <div
                className="container-fluid hd-projects"
                style={{
                    backgroundImage: `url(${banner || "/images/headers/project.jpg"})`,
                    backgroundRepeat: 'no-repeat',
                }}
            >
            </div>
        </div>
    )
}


// class HeaderStatic extends React.Component {

//     render() {
//         return (
//             <div className="container-fluid hd-projects">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-12 col-lg-6 hd-logo">
//                             <Link to={`/`}><img src="/images/logow.png" width="300" alt="Main Logo"></img></Link>
//                         </div>
//                         <div className="col-12 col-lg-6 no-wrap text-right hd-flex">
//                             <p>
//                                 <span className="txt-link"><Link to={`/`} style={{ color: '#fff' }}>Home</Link></span>
//                                 <span className="txt-link"><Link to={`/projects`} style={{ color: '#fff', bottom: 0 + 'px' }}>Projects</Link></span>
//                                 <span className="txt-link"><Link to={`/team`} style={{ color: '#fff' }}>Our Team</Link></span>
//                                 <span className="txt-link"><Link to={`/about`} style={{ color: '#fff' }}>About</Link></span>
//                                 <span className="txt-link p-0"><Link to={`/contact`} style={{ color: '#fff' }}>Contact</Link></span>
//                             </p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )
//     }

// }

// export default HeaderStatic;