import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { UserAuth } from '../context/AuthContext'
import { db } from '../firebase/firebase'
import { onValue, ref } from 'firebase/database'
import Table from 'react-bootstrap/Table';
import { Link, useParams } from 'react-router-dom';
import AdminHeader from './AdminHeader';


const AdminEditList = () => {
    const { logOut } = UserAuth();
    const handleLogout = async (e) => {
        e.preventDefault();
        try {
            await logOut()
        } catch (error) {
            console.log(error)
        }
    }
    const params = useParams();
    const [projects, setProjects] = useState(['Loading...']);
    const pathname = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)

    useEffect(() => {
        const query = ref(db, `/${pathname}`);
        let content = [];
        onValue(query, (snapshot) => {
            if (snapshot.val()) {
                const data = snapshot.val();
                for (let i = 0; i < data.length; i++) {
                    const item = data[i];
                    content.push(
                        <tr key={i}>
                            <td>{item.projectName}</td>
                            <td>{item.projectLocation}</td>
                            <td><Link to={`./${i}`}><Button>Edit</Button></Link></td>
                        </tr>
                    )
                }
            } else {
                content.push(['Loading...'])
            }
            setProjects(content)
        });
    }, [params, pathname])


    return (
        <div className="container-fluid bg-grey">
            <AdminHeader />
            <div className="container mt-4">
                <div className="row mt-4 mt-lg-0">
                    <div className="col-12 col-lg-1 order-3 order-lg-1 p-0 m-0 mt-4 mt-lg-0">
                        <Link to="/admin">
                            <Button className="w-100" variant="primary">
                                Back
                            </Button>
                        </Link>
                    </div>
                    <div className="col-12 col-lg-10 order-2"></div>
                    <div className="col-12 col-lg-1 order-1 order-lg-3 p-0 m-0">
                        <Button className="w-100" variant="primary" onClick={handleLogout}>
                            Logout
                        </Button>
                    </div>
                </div>
                <div className="row mt-5">
                    {projects === ["Loading..."] ? "Loading..."
                        :
                        <Table striped bordered hover>
                            <thead className="w-auto">
                                <tr>
                                    <th>Project Name</th>
                                    <th>Project Location</th>
                                    <th>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {projects}
                            </tbody>
                        </Table>
                    }
                </div>
            </div >
        </div>

    );
}

export default AdminEditList;
