import DropzoneComponent from 'react-dropzone-component';
import { getStorage, ref } from "firebase/storage";


const storage = getStorage();

const componentConfig = {
    iconFiletypes: ['.jpg'],
    showFiletypeIcon: true,
    postUrl: 'none'
};

const djsConfig = {
    autoProcessQueue: false,
    acceptedFiles: "image/jpeg",
    addRemoveLinks: true,
}

const eventHandlers = {
    thumbnail: async function (file) {
        var myEvent = this;
        var progressElement = file.previewElement.querySelector('[data-dz-uploadprogress]');
        await processFile(file, progressElement, myEvent);
    }
}

const processFile = (file, progressElement, event) => {
    return new Promise((resolve, reject) => {

        var storageRef = storage.ref();
        var uploadTask = storageRef.child(`${file.name}`).putString(file.dataURL, 'data_url');

        uploadTask.on('state_changed', function (snapshot) {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            progressElement.style.width = `${progress}%`;
            switch (snapshot.state) {
                case 'paused':
                    console.log(file.name + ': Upload is paused');
                    break;
                case 'running':
                    console.log(file.name + ': Upload is running');
                    break;
                default:
            }
        }, function (error) {
            // Handle unsuccessful uploads
            console.log(error);
            event.emit('complete', file);
            event.emit('error', file);
            reject();
        }, function () {
            // File upload completed
            event.emit('complete', file);
            event.emit('success', file);
            resolve();
        });
    });
}

const DropWithPost = () => {
    return (<div>
        <DropzoneComponent config={componentConfig}
            eventHandlers={eventHandlers}
            djsConfig={djsConfig}
        />
    </div>
    )
}


export { DropWithPost }