import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class Footer extends Component {

    render() {
        return (
            <div>
                <div className="container-fluid footer">
                    <div className="container pb-4">
                        <div className="row m-0 p-0 mr-0 text-left txt-footer">
                            <div className="col-12 col-md-2 p-0 m-0">
                            <Link className="txt-link-footer" to={`/projects/defence`}><p>DEFENCE</p></Link>
                                <p>Campbell Barracks</p>
                                <p>RAAF Bullsbrook</p>
                                <p>RAAF Gin Gin</p>
                                <p>RAAF Eclipse Hill</p>
                                <p>HMAS Stirling</p>
                            </div>
                            <div className="col-12 col-md-2 p-0 m-0">
                            <Link className="txt-link-footer" to={`/projects/education`}><p>EDUCATION</p></Link>
                                <p>Curtin TL Robertson Library</p>
                                <p>Curtin Exchange</p>
                                <p>Curtin Industry Space</p>
                                <p>Curtin School of Design and the Built Environment</p>
                            </div>
                            <div className="col-12 col-md-2 p-0 m-0 ps-md-4">
                                <Link className="txt-link-footer" to={`/projects/commercial`}><p>COMMERCIAL</p></Link>
                                <p>Ritz Carlton</p>
                                <p>Cloisters on Hay</p>
                                <p>Ballet Centre</p>
                            </div>
                            <div className="col-12 col-md-2 p-0 m-0">
                                <Link className="txt-link-footer" to={`/projects/health`}><p>HEALTH</p></Link>
                                <p>Hollywood Mental Health Unit</p>
                                <p>New Children’s Hospital</p>
                            </div>
                            <div className="col-12 col-md-2 p-0 m-0">
                                <Link className="txt-link-footer" to={`/projects/retail`}><p>RETAIL</p></Link>
                                <p>Forrest Chase</p>
                                <p>Baldivis Shopping Centre</p>
                                <p>Whitfords Shopping Centre</p>
                            </div>
                            <div className="col-12 col-md-2 p-0 m-0">
                                <Link className="txt-link-footer" to={`/projects/residential`}><p>MEDIUM TO HIGH-RISE RESIDENTIAL</p></Link>
                                <p>Pinnacle Appartments</p>
                                <p>Ritz Carlton</p>
                                <p>Aria Apartments</p>
                                <p>The Westin Hotel</p>
                                <p>The Pocket</p>
                                <p>The Grandstand and The Reserve</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid footer1">
                    <div className="container pt-3 text-center">
                        <div className="row d-flex">
                            <div className="col-12 col-md-4 order-1 order-sm-2 my-auto text-small text-sm-center text-md-left"><p>Copyright ANWEST Constructions 2023</p></div>
                            <div className="col-12 col-md-4 order-0 order-sm-2 my-auto text-small text-sm-center"><p>ABN: 60 619 487 759</p></div>
                            <div className="col-12 col-md-4 order 2 order-sm-3 my-auto text-small text-sm-center text-md-right "><p>ABN: 58 128 410 935</p></div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
