import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './components/Home'
import Projects from './components/Projects'
import ProjectType from './components/ProjectType'
import AdminLogin from './components/AdminLogin'
import Admin from './components/Admin'
import AdminEditList from './components/AdminEditList'
import AdminEditItem from './components/AdminEditItem'
import About from './components/About'
import Contact from './components/Contact'
import Team from './components/Team'
import ProtectedRoute from './components/ProectedRoute'

function App() {
    return (
        <div>
            <Routes>
                <Route exact path="/" element={<Home />}></Route>
                <Route path="/about" element={<About />}></Route>
                <Route path="/contact" element={<Contact />}></Route>
                <Route path="/projects" element={<Projects />}></Route>
                <Route path="/projects/:id" element={<ProjectType />}></Route>
                <Route path="/team" element={<Team />}></Route>
                <Route path="/admin/login" element={<AdminLogin />}></Route>
                <Route path="/admin" element={<ProtectedRoute><Admin /></ProtectedRoute>}></Route>
                <Route path="/admin/edit/:type" element={<ProtectedRoute><AdminEditList /></ProtectedRoute>}></Route>
                <Route path="/admin/edit/:type/:id" element={<ProtectedRoute><AdminEditItem /></ProtectedRoute>}></Route>
            </Routes>
        </div>
    )
}

export default App;