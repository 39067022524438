import React, { Component } from 'react';
import Header from './HeaderStatic';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import $ from 'jquery';


class Projects extends Component {
    componentDidMount() {
        document.querySelector('body').scrollIntoView({
            block: "start"
        })
    }

    render() {
        return (
            <div>
                <Header banner={"/images/headers/7.jpg"} />
                <div className="container bgwhite mt-5 mb-5">
                    <div className="mt-3">
                        <h2>View our recent projects...</h2>
                    </div>
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12 col-md-6 col-lg-3 mb-5">
                            <Link to="/projects/defence">
                                <div className="prod_img_view img-hover mb-3">
                                    <figure className="m-0 p-0"><img className="img-round" src="./images/projects/defence.jpg" alt="Defense" /></figure>
                                </div>
                            </Link>
                            <div>
                                <h2>DEFENCE</h2>
                                <p>Read more about our defence projects.....</p>
                                <Link to="/projects/defence">
                                    <button type="button" onClick={this.handleClick} className="btn btn-outline-dark">Read More</button>
                                </Link>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3 mb-5">
                            <Link to="/projects/education">
                                <div className="prod_img_view img-hover mb-3">
                                    <figure className="m-0 p-0"><img className="img-round" src="/images/projects/education.jpg" alt="Education" /></figure>
                                </div>
                            </Link>
                            <div>
                                <h3>EDUCATION</h3>
                                <p>Read more about our education projects.....</p>
                                <Link to="/projects/education"><button type="button" className="btn btn-outline-dark">Read More</button></Link>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3 mb-5">
                            <Link to="/projects/commercial">
                                <div className="prod_img_view img-hover mb-3">
                                    <figure className="m-0 p-0"><img className="img-round" src="./images/projects/commercial.jpg" alt="Commercial" /></figure>
                                </div>
                            </Link>
                            <div>
                                <h3>COMMERCIAL</h3>
                                <p>Read more about our commercial projects.....</p>
                                <Link to="/projects/commercial"><button type="button" className="btn btn-outline-dark">Read More</button></Link>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3 mb-5">
                            <Link to="/projects/health">
                                <div className="prod_img_view img-hover mb-3">
                                    <figure className="m-0 p-0"><img className="img-round" src="./images/projects/health.jpg" alt="Health" /></figure>
                                </div>
                            </Link>
                            <div>
                                <h3>HEALTH</h3>
                                <p>Read more about our commercial projects.....</p>
                                <Link to="/projects/health"><button type="button" className="btn btn-outline-dark">Read More</button></Link>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3 mb-5">
                            <Link to="/projects/retail">
                                <div className="prod_img_view img-hover mb-3">
                                    <figure className="m-0 p-0"> <img className="img-round" src="./images/projects/retail.jpg" alt="Retail" /></figure>
                                </div>
                            </Link>
                            <div>
                                <h3>RETAIL</h3>
                                <p>Read more about our retail projects.....</p>
                                <Link to="/projects/retail"><button type="button" className="btn btn-outline-dark">Read More</button></Link>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3 mb-5">
                            <Link to="/projects/residential">
                                <div className="prod_img_view img-hover mb-3">
                                    <figure className="m-0 p-0"><img className="img-round" src="./images/projects/pinnacle.jpg" alt="Residential" /></figure>
                                </div>
                            </Link>
                            <div>
                                <h3>MEDIUM TO HIGH-RISE RESIDENTIAL</h3>
                                <p>Read more about our medium to high-rise residential projects.....</p>
                                <Link to="/projects/residential"><button type="button" className="btn btn-outline-dark">Read More</button></Link>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3 mb-5">
                            <Link to="/projects/regional">
                                <div className="prod_img_view img-hover mb-3">
                                    <figure className="m-0 p-0"><img className="img-round" src="/images/projects/target.jpg" alt="Regional" /></figure>
                                </div>
                            </Link>
                            <div>
                                <h3>REGIONAL</h3>
                                <p>Read more about our regional projects.....</p>
                                <Link to="/projects/regional"><button type="button" className="btn btn-outline-dark">Read More</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div >
        );
    }
}

export default Projects;
