import React from 'react';
import Button from 'react-bootstrap/Button';
import { UserAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';


const Admin = () => {
    const { logOut } = UserAuth();
    const handleLogout = async (e) => {
        e.preventDefault();
        try {
            await logOut()
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="container-fluid bg-grey vh-100">
            <div className="container hd-admin">
                <div className="row">
                    <div className="col-12 col-lg-4 d-flex justify-content-center justify-content-lg-start">
                        <img className="text-center" src="/images/currlogo.png" width="300" alt="Main Logo"></img>
                    </div>
                    <div className="col-12 col-lg-8 d-flex justify-content-center">
                        <div className="align-self-center">
                            <h1 className="p-0 m-0">SUPER SECRET ADMIN AREA</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-4">
                <div className="row mt-4 mt-lg-0">
                    <div className="col-12 col-lg-11 order-2 order-lg-1 p-0 m-0 mt-4 mt-lg-0"><h2>Please select an area to edit...</h2></div>
                    <div className="col-12 col-lg-1 order-1 order-lg-2 p-0 m-0">
                        <Button className="w-100" variant="primary" onClick={handleLogout}>
                            Logout
                        </Button>
                    </div>
                </div>
                <div className="row mt-4 mt-lg-0">
                    {/* <div className="col-6 col-lg-3 admin-image p-4 d-flex">
                        <Link to={'./edit/home'}>
                            <img className="img-fluid" src='/images/greenbg.jpg' alt='Home'></img>
                            <h1 className="admin-image-txt p-0 m-0">HOME</h1>
                        </Link>
                    </div> */}
                    <div className="col-6 col-lg-3 admin-image p-4 d-flex">
                        <Link to={'./edit/defense'}>
                            <img className="img-fluid" src='/images/greenbg.jpg' alt=" Defense"></img>
                            <h1 className="admin-image-txt p-0 m-0">DEFENSE</h1>
                        </Link>
                    </div>
                    <div className="col-6 col-lg-3 admin-image p-4 d-flex">
                        <Link to={'./edit/residential'}>
                            <img className="img-fluid" src='/images/greenbg.jpg' alt="Residential"></img>
                            <h1 className="admin-image-txt p-0 m-0">RESIDENTIAL</h1>
                        </Link>
                    </div>
                    <div className="col-6 col-lg-3 admin-image p-4 d-flex">
                        <Link to={'./edit/commercial'}>
                            <img className="img-fluid" src='/images/greenbg.jpg' alt="Commercial"></img>
                            <h1 className="admin-image-txt p-0 m-0">COMMERCIAL</h1>
                        </Link>
                    </div>
                    <div className="col-6 col-lg-3 admin-image p-4 d-flex">
                        <Link to={'./edit/health'}>
                            <img className="img-fluid" src='/images/greenbg.jpg' alt="Health"></img>
                            <h1 className="admin-image-txt p-0 m-0">HEALTH</h1>
                        </Link>
                    </div>
                    <div className="col-6 col-lg-3 admin-image p-4 d-flex">
                        <Link to={'./edit/retail'}>
                            <img className="img-fluid" src='/images/greenbg.jpg' alt="Retail"></img>
                            <h1 className="admin-image-txt p-0 m-0">RETAIL</h1>
                        </Link>
                    </div>
                    <div className="col-6 col-lg-3 admin-image p-4 d-flex">
                        <Link to={'./edit/regional'}>
                            <img className="img-fluid" src='/images/greenbg.jpg' alt="Regional"></img>
                            <h1 className="admin-image-txt p-0 m-0">REGIONAL</h1>
                        </Link>
                    </div>
                    <div className="col-6 col-lg-3 admin-image p-4 d-flex">
                        <Link to={'./edit/education'}>
                            <img className="img-fluid" src='/images/greenbg.jpg' alt="Education"></img>
                            <h1 className="admin-image-txt p-0 m-0">EDUCATION</h1>
                        </Link>
                    </div>
                    {/* <div className="col-6 col-lg-3 admin-image p-4 d-flex">
                        <Link to={'./edit/team'}>
                            <img className="img-fluid" src='/images/greenbg.jpg' alt="Team"></img>
                            <h1 className="admin-image-txt p-0 m-0">OUR TEAM</h1>
                        </Link>
                    </div>
                    <div className="col-6 col-lg-3 admin-image p-4 d-flex">
                        <Link to={'./edit/about'}>
                            <img className="img-fluid" src='/images/greenbg.jpg' alt="About"></img>
                            <h1 className="admin-image-txt p-0 m-0">ABOUT US</h1>
                        </Link>
                    </div> */}
                </div>
            </div>
        </div >

    );
}

export default Admin;
