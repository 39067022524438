import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD-CLj_le__PZ2JvWOv9LEJuRKCFzIisRo",
  authDomain: "anwest-new.firebaseapp.com",
  databaseURL: "https://anwest-new-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "anwest-new",
  storageBucket: "anwest-new.appspot.com",
  messagingSenderId: "1058161471497",
  appId: "1:1058161471497:web:324ba4f5ee6c4e57d9c8d8",
  measurementId: "G-BZB3JH57FH"
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage }
