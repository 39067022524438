import React from 'react';
import { Link } from 'react-router-dom'

class HeaderFade extends React.Component {

    render() {
        return (
            <div className="container-fluid d-sm-block hd-test hd-fade hd-container">
                {/* <div className="container hd-container"> */}
                    <div className="row">
                    <div className="col-12 col-lg-5 offset-lg-1 hd-logo">
                            <Link to={`/`}><img src="/images/logocw.png" className="logo-big" alt="Main Logo"></img></Link>
                        </div>
                        <div className="col-md-5 no-wrap text-right hd-flex">
                            <p className="mt-0 mb-2">
                                <span className="txt-link"><Link to={`/`} style={{ color: '#fff' }}>HOME</Link></span>
                                <span className="txt-link"><Link to={`/projects`} style={{ color: '#fff', bottom: 0 + 'px' }}>PROJECTS</Link></span>
                                <span className="txt-link"><Link to={`/team`} style={{ color: '#fff' }}>OUR TEAM</Link></span>
                                <span className="txt-link"><Link to={`/about`} style={{ color: '#fff' }}>ABOUT</Link></span>
                                <span className="txt-link p-0"><Link to={`/contact`} style={{ color: '#fff' }}>CONTACT</Link></span>
                            </p>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                {/* </div> */}
            </div>
        )
    }

}

export default HeaderFade;