import React, { Component } from 'react';
import Header from './HeaderStatic';
import Footer from './Footer';
import $ from 'jquery';

class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        document.querySelector('body').scrollIntoView({
            block: "start"
        })
    }

    render() {
        return (
            <div>
                <Header banner={"/images/headers/11.jpg"} />
                <div className="container-fluid headerline">
                    <div className="container">
                        <div className="row mt-5 mb-5">
                            <div className="col-lg-6 p-0 m-0 pr-3">
                                <div className="mb-5">
                                    <h2>Contact Us:</h2>
                                    <br />
                                    <p>Anthony Jones - Director</p>
                                    <p>Email: <a href="mailto:anthony@anwest.com.au?subject=Website%20Enquiry">anthony@anwest.com.au</a></p>
                                </div>
                            </div>
                            <div className="col-lg-6 p-0 m-0 pl-lg-3">
                                <div className="missiondiv bgdgreen w-100 text-center p-1 p-lg-5">
                                    <div className="p-1 p-lg-4">
                                        <h1 className="lgreen h1small">WE BELIEVE IN PROFESSIONALISM, TRUST AND CREATIVITY</h1>
                                        <p className="txt-white bold mt-4">Anwest approach is practicality. We are proud of our experience and successful delivery of projects with a systematic and professional manner. We have a team of experienced engineers, construction managers and supervisors, as well as tradesmen who have been with Anwest for more than 10 years. This has resulted in a team that, with varied backgrounds and skills, can cohesively work together giving specialised solutions to the construction matters part of our lives.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div >
        );
    }
}

export default Contact;
