import React, { Component, useEffect, useState } from 'react';
import GetProjectType from './GetProjectType';
import ProjectTypeItem from './ProjectTypeItem';
import HeaderStatic from './HeaderStatic'
import Footer from './Footer'
import { Link, useLocation } from "react-router-dom";
import { db } from '../firebase/firebase'
import { onValue, ref } from 'firebase/database'
import { browserHistory } from 'react-router';

const ProjectType = () => {

    const location = useLocation();
    const [projects, setProjects] = useState([])

    useEffect(() => {
        document.querySelector('body').scrollIntoView({
            block: "start"
        })
        getProjects()
    }, [location])

    const getProjects = () => {
        const pathname = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
        const query = ref(db, `/${pathname}`);
        let content = [];
        onValue(query, (snapshot) => {
            if (snapshot.val()) {
                const data = snapshot.val();
                for (let i = 0; i < data.length; i++) {
                    const item = data[i];
                    if (item !== undefined) content.push(<ProjectTypeItem item={item} key={i} allProjects={data} currentPosition={i} />);
                }
            } else {
                content.push(<p key="1">No data</p>)
            }
            setProjects(content)
        });
    }


    return (
        <div>
            <HeaderStatic />
            <div className="container mt-5 mb-5">
                <Link to="/projects">
                    <button type="submit" className="btn btn-outline-dark"><span className="bi-chevron-left"></span> All Projects</button>
                </Link>
                <GetProjectType />
                <div className="row mt-3">
                    {projects}
                </div>
            </div>
            <Footer />
        </div >
    );

}

export default ProjectType;
