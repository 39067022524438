import React, { Component, useEffect } from 'react';
import Header from './HeaderStatic';
import Footer from './Footer';
import $ from 'jquery';

class Aboutus extends Component {

    componentDidMount() {
        // Move page to top on load
        document.querySelector('body').scrollIntoView({
            block: "start"
        });
    }

    render() {
        return (
            <div>
                <Header banner={"/images/headers/07.jpg"} />
                <div className="container-fluid headerline">
                    <div className="container">
                        <div className="row p-0 m-0 mt-3">
                            {/* LEFT SIDE */}
                            <div className="col-lg-5 p-0 m-0 pr-4 mt-5">
                                <h2>Our Company</h2>
                                <p>Anwest is a privately owned West Australian business operating in the construction services sector since 2007. Anwest was started in 2007 by brothers Nigel and Anthony Jones.</p>
                                <p>Over the past 13 years, Anwest has completed a variety of projects throughout Western Australia, including metropolitan Perth and regional WA ranging from defence bases, shopping centres, apartment buildings, bank branches, heritage refurbishments, and office fit-outs. Anwest as quickly established a reputation for completing projects on time, on budget and to a very high standard.</p>
                                <p>Our aim is to maintain business by providing a superior quality service to our customers and approaching all our business dealings in a highly professional manner based on mutual trust and respect.</p>
                                <h2 className="mt-3">Capabilities</h2>
                                <p>Anwest is a registered builder and also a “Supply Nation” registered business servicing the construction industry within Defence, Government, Commercial, Residential, Retail, and Health industries.</p>
                            </div>
                            {/* RIGHT SIDE */}
                            <div className="col-lg-7 p-0 m-0 pl-lg-3 mt-5">
                                <div className="missiondiv bgdgreen w-100 text-center p-1 p-lg-5">
                                    <div className="p-1 p-lg-4">
                                        <h1 className="lgreen h1small">WE BELIEVE IN PROFESSIONALISM, TRUST AND CREATIVITY</h1>
                                        <p className="txt-white bold mt-4">Anwest approach is practicality. We are proud of our experience and successful delivery of projects with a systematic and professional manner. We have a team of experienced engineers, construction managers and supervisors, as well as tradesmen who have been with Anwest for more than 10 years. This has resulted in a team that, with varied backgrounds and skills, can cohesively work together giving specialised solutions to the construction matters part of our lives.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row pt-0 p-0 m-0">
                            <div className="col-12 p-0 m-0 mt-5 mt-lg-0">
                                <h2 className="">Expertise</h2>
                                <p>Anwest is an expert contractor in the ceilings and partitions market, this has being our core expertise since our beginnings. We specialise in the design and construction of partition and ceilings systems in lightweight construction, this includes fire rated, shaft, and external wind-loaded solutions, and external cladding. We have worked in conjunction with the most trusted nation-wide and global manufacturers of the materials for more than a decade now and can resolve any type of system from design to construction.</p>
                                <p>For the last 5 years, Anwest has excelled in the high end specialised feature finish products which has improved our capabilities and the expertise of our tradesmen. Customised timber feature walls and ceilings, aluminium composite materials, and laminated and CFC panel solutions for facades and soffits are among some of the highly specialised materials we can design and construct based on the project requirements.</p>
                                <p>Anwest has also worked in the majority of trade packages for the commercial and residential industries which can be part of a one-stop solution for projects that have such requirement. To name some examples are earthworks, concrete structures, structural steel, aluminium partitioning and glazing, demolition, fit-outs, general carpentry, and landscaping.</p>
                            </div>
                        </div>
                        {/* <div className="row pt-4 p-0 m-0 mb-5">
                            <div className="col-12 col-lg-9 p-0 m-0 mb-4 mb-md-0 supplynationdiv bglgreen">
                                <div className="p-1 p-lg-5">
                                    <div className="p-2 pr-lg-5 text-center white">
                                        <h2 className="h1small">Supply Nation Registered</h2>
                                        <p className="bold">Anwest under Anwest Constructions (WA) Pty Ltd is also a Supply Nation registered builder which, once more, denotes our compromise and professionalism with our community, specially in Western Australia. We welcome the diversity of our workforce and successfully employ indigenous personnel.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 p-0 m-0 align-self-center">
                                <img src="/images/supplynation.png" width="100%"></img>
                            </div>
                        </div> */}
                    </div>
                </div>
                <Footer />
            </div>

        );
    }
}

export default Aboutus;
