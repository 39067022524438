import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { UserAuth } from '../context/AuthContext'
import { db } from '../firebase/firebase'
import { onValue, ref, update } from 'firebase/database'
import { Link, useNavigate, useParams } from 'react-router-dom';
import AdminHeader from './AdminHeader'
import Modal from 'react-bootstrap/Modal';
import { DropWithPost } from './DropZone';


const AdminEditItem = () => {
    const { logOut } = UserAuth();
    const navigate = useNavigate();
    const params = useParams();
    const [show, setShow] = useState(false);
    const [projectDescription, setProjectDescription] = useState();
    const [projectDescription2, setProjectDescription2] = useState();
    const [projectHeroImage, setProjectHeroImage] = useState();
    const [projectLocation, setProjectLocation] = useState();
    const [projectName, setProjectName] = useState();
    const [projectSize, setProjectSize] = useState();
    const [projectType, setProjectType] = useState();
    const [projectImages, setProjectImages] = useState([]);

    useEffect(() => {
        const query = ref(db, `/${params.type}/${params.id}`);
        onValue(query, (snap) => {
            setProjectDescription(snap.val().projectDescription);
            setProjectDescription2(snap.val().projectDescription2);
            setProjectHeroImage(snap.val().projectHeroImage);
            setProjectLocation(snap.val().projectLocation);
            setProjectName(snap.val().projectName);
            setProjectSize(snap.val().projectSize);
            setProjectType(snap.val().projectType);
            setProjectImages(snap.val().projectImages);
        });
    }, [params])


    const handleLogout = async (e) => {
        e.preventDefault();
        try {
            await logOut()
        } catch (error) {
            console.log(error)
        }
    }

    const handleModalClose = () => setShow(false);

    const handleSave = async (e) => {
        const updates = {
            projectDescription,
            projectDescription2,
            projectHeroImage,
            projectLocation,
            projectName,
            projectSize,
            projectType,
            projectImages,
        }
        try {
            update(ref(db, `/${params.type}/${params.id}`), updates);
            setShow(true)

            setTimeout(() => {
                navigate(`/admin/edit/${params.type}`)
            }, 2000)


        } catch (error) {
            console.log(error);
        }
    }

    const deleteImage = (e) => {
        setProjectImages((current) =>
            current.filter((image) => image.original !== e.target.id)
        );
    }


    return (
        <div className="container-fluid bg-grey">
            <AdminHeader />
            <div className="container mt-4">
                <div className="row mt-4 mt-lg-0">
                    <div className="col-12 col-lg-1 order-3 order-lg-1 p-0 m-0 mt-4 mt-lg-0">
                        <Link to={`/admin/edit/${params.type}`}>
                            <Button className="w-100" variant="primary">
                                Back
                            </Button>
                        </Link>
                    </div>
                    <div className="col-12 col-lg-10 order-2"></div>
                    <div className="col-12 col-lg-1 order-1 order-lg-3 p-0 m-0">
                        <Button className="w-100" variant="primary" onClick={handleLogout}>
                            Logout
                        </Button>
                    </div>
                </div>
                <div className="row mt-4">
                    {projectName === ["Loading..."] ? "Loading..."
                        :
                        <div>
                            <div className="row">
                                <div className="col-12 col-lg-2 ps-0">
                                    <label className="col-form-label">Project Name</label>
                                </div>
                                <div className="col-12 col-lg-3 ps-0">
                                    <input type="text" onChange={e => setProjectName(e.target.value)} defaultValue={projectName} className="form-control"></input>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 col-lg-2 ps-0">
                                    <label className="col-form-label">Project Location</label>
                                </div>
                                <div className="col-12 col-lg-3 ps-0">
                                    <input type="text" onChange={e => setProjectLocation(e.target.value)} defaultValue={projectLocation} className="form-control"></input>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 col-lg-2 ps-0">
                                    <label className="col-form-label">Project Size</label>
                                </div>
                                <div className="col-12 col-lg-3 ps-0">
                                    <input type="text" onChange={e => setProjectSize(e.target.value)} defaultValue={projectSize} className="form-control"></input>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 col-lg-2 ps-0">
                                    <label className="col-form-label">Project Description</label>
                                </div>
                                <div className="col-12 col-lg-8 ps-0">
                                    <textarea rows="6" type="text" onChange={e => setProjectDescription(e.target.value)} defaultValue={projectDescription} className="form-control"></textarea>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 col-lg-2 ps-0">
                                    <label className="col-form-label">Project Description 2</label>
                                </div>
                                <div className="col-12 col-lg-8 ps-0">
                                    <textarea rows="4" type="text" onChange={e => setProjectDescription2(e.target.value)} defaultValue={projectDescription2} className="form-control"></textarea>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 col-lg-2 ps-0">
                                    <label className="col-form-label">Images</label>
                                </div>
                            </div>
                            <div className="row mt-3">
                                {projectImages.map(i => (
                                    <div key={i.original} className="col-4 col-lg-2 ps-0">
                                        <img className="img-fluid" src={i.original} alt="Project"></img>
                                        <p className="text-center">
                                            {i.original === projectHeroImage ? 'Is Hero' : <button type="button" onClick={(e) => { setProjectHeroImage(e.target.id) }} id={i.original} className="btn btn-link">Set Hero</button>}
                                            {<button type="button" onClick={deleteImage} id={i.original} className="btn btn-link"><span className="bi-trash3"></span></button>}</p>
                                    </div>
                                ))}

                            </div>
                            <div className="row mt-3">
                                <div className="col-12 p-0 m-0">



                                    {/* <FileDrop /> */}
                                    <DropWithPost /> 



                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 col-lg-2 ps-0">
                                    <Button className="w-100" variant="primary" onClick={handleSave}>
                                        Save Changes
                                    </Button>
                                    <Modal show={show} onHide={handleModalClose} dialogClassName="modal-40w">
                                        <Modal.Body className="bg-lgreen d-flex justify-content-center">
                                            <div className="container p-3 m-0">
                                                <p className="text-center">Changes have been saved</p>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div >
        </div>

    );
}

export default AdminEditItem;
