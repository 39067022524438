import React from 'react';


const AdminHeader = () => {
    return (
        <div className="container-fluid">
            <div className="container hd-admin">
                <div className="row">
                    <div className="col-12 col-lg-4 d-flex justify-content-center justify-content-lg-start">
                        <img className="text-center" src="/images/currlogo.png" width="300" alt="Main Logo"></img>
                    </div>
                    <div className="col-12 col-lg-8 d-flex justify-content-center">
                        <div className="align-self-center">
                            <h1 className="p-0 m-0">SUPER SECRET EDITING AREA</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminHeader;
