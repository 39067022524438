import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { UserAuth } from '../context/AuthContext'
import {useNavigate} from 'react-router-dom'

const AdminLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const {user, emailSignIn} = UserAuth();
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await emailSignIn(email, password);
        } catch (error) {
            setErrorMessage('Unknown username and/or password')
        }
    }

    useEffect(() => {
        if(user != null) {
            navigate('/admin')
        }
    }, [user, navigate])


    return (
        <div className="container-fluid carousel bg-grey vh-100">
            <div className='row'>
                <div className="col-12 col-lg-6">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 text-center">
                                <img className="mb-5 text-center" src="/images/currlogo.png" width="300" alt="Main Logo"></img></div>
                            <div className="col-8 align-self-center justify-content-center">
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Enter email" />
                                        <Form.Text className="text-muted">
                                            Please use a valid @anwest.com.au email address
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                                    </Form.Group>
                                    <Button variant="primary" onClick={handleLogin}>
                                        Login
                                    </Button>
                                    {errorMessage && <p className="mt-4 error">{errorMessage}</p>}
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 d-none d-lg-block">
                    <div className="carousel">
                        <div className="carousel-item carousel-image bg-img-1 active"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminLogin;
