import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import * as serviceWorker from './serviceWorker';
import "babel-polyfill"
import React from 'react';
import App from './App'
import ReactDOM from 'react-dom';
import { AuthContextProvider } from './context/AuthContext'
import { BrowserRouter } from "react-router-dom";
import $ from 'jquery';

import './styles/header.css';
import './styles/carousel.css';
import './styles/admin.css'
import './styles/general.css';
import './styles/text.css';
import './styles/projects.css';
import './styles/images.css';
import './styles/about.css';
import './styles/team.css';
import './styles/image-gallery.css';
import '/node_modules/react-dropzone-component/styles/filepicker.css';
import '/node_modules/dropzone/dist/min/dropzone.min.css'

$(window).on("scroll touchmove", function () {
  $('.hd-fade').toggleClass('hd-tiny', $(document).scrollTop() > 80);
  $('.logo-big').toggleClass('logo-tiny', $(document).scrollTop() > 80);
  if ($(document).scrollTop() > 80) {
    if ($(window).width() > 992) {
      $(".logo-tiny").attr("src", "/images/logoonly.png")
    }
  } else {
    $(".logo-big").attr("src", "/images/logocw.png")
  }
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <AuthContextProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AuthContextProvider>
);

serviceWorker.unregister();
